<template>
    <div>
        <a-card style="background-color: #fff;" v-if="hasPermission('QUERY_CAKE_MAILL')">
            <div :class="advanced ? 'search' : null">
                <a-form layout="horizontal">
                    <div :class="advanced ? null : 'fold'">
                        <a-row>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="客户经理" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.staffIds" show-search mode="multiple"
                                        :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                                        style="width: 100%" placeholder="请选择客户经理" :default-value="null"
                                        option-label-prop="label">
                                        <a-select-option :value="item.id" :label="item.name"
                                            v-for=" (item, index) in users" :key="index">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24" style="max-width: 400px;margin-left: 20px;margin-top: 5px;">
                                <a-input-group compact>
                                    <a-select :default-value="selectTimeType" v-model="selectTimeType" @change="checkoutTypeChange">
                                        <a-select-option :value="1">
                                            创建时间
                                        </a-select-option>
                                        <a-select-option :value="2">
                                            拜访时间
                                        </a-select-option>
                                    </a-select>
                                    <a-range-picker v-if="selectTimeType == 1" :value="dateValue" :ranges="{
            '今天': [moment(), moment()],
            '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')], '当月': [moment().startOf('month'), moment().endOf('month')], '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }" @change="dateChange" />
                                    <a-range-picker v-if="selectTimeType == 2" :value="dateVisiValue" :ranges="{
            '今天': [moment(), moment()],
            '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')], '当月': [moment().startOf('month'), moment().endOf('month')], '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }" @change="dateVisiChange" />
                                </a-input-group>
                            </a-col>
                            <!-- <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-range-picker :value="dateValue" :ranges="{
            '今天': [moment(), moment()],
            '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')], '当月': [moment().startOf('month'), moment().endOf('month')], '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }" @change="dateChange" />

                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="拜访时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-range-picker :value="dateVisiValue" :ranges="{
            '今天': [moment(), moment()],
            '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')], '当月': [moment().startOf('month'), moment().endOf('month')], '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }" @change="dateVisiChange" />

                                </a-form-item>
                            </a-col> -->
                                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                    @click="getData">查询</a-button>
                        </a-row>
                        <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
            queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
                    </div>

                </a-form>

            </div>


            <div>
                <standard-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
                    <div slot="createTime" slot-scope="{text}">{{ formatDate(text) }}</div>
                    <div slot="status" slot-scope="{text}">
                        <span v-if="text == 1" style="color: #008000;">上架</span>
                        <span v-if="text == 2" style="color: #ff0000;">下架</span>
                    </div>
                    <span slot="offlineVisitsTitle">
                        线下拜访记录

                        <a-tooltip>
                            <template slot="title">
                                在时间段内相同客户拜访多次仅统计一次
                            </template>
                            <a-icon type="question-circle" />
                        </a-tooltip>
                    </span>

                    <div slot="icon" slot-scope="{text}"><img style="width: 45px;" :src="text" alt=""></div>

                </standard-table>
                <div class="page-box">
                    <a-pagination :total="total" :current="currentPage" :page-size-options="pageSizeOptions"
                        :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                        show-size-changer />
                </div>
            </div>
        </a-card>

        <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-else>
            <a-empty :description="false" />
            无权限查看
        </div>
    </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
const columns = [
    { title: '序号', customRender: (text, record, index) => index + 1, align: 'center' },
    {
        title: '客户经理',
        dataIndex: 'staffName',
        align: 'center'

    },
    {
        title: '新增',
        dataIndex: 'customerNumber',
        align: 'center'
    },
    {
        // title: '线下拜访记录',
        dataIndex: 'offlineVisits',
        slots: { title: 'offlineVisitsTitle' },
        align: 'center'
    },
    {
        title: '线上沟通记录',
        dataIndex: 'onlineCommunication',
        align: 'center'
    },
    {
        title: '成交',
        dataIndex: 'dealNumber',
        align: 'center'
    }
]

export default {
    name: 'QueryList',
    components: { StandardTable },
    data() {
        return {
            moment,
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            dateVisiValue: '',
            total: 0,
            // 筛选
            queryData: {
                "page": 0,
                "size": 10,
                "beginCreateTime": "",
                "endCreateTime": "",
                "staffIds": []
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            currentPage: 1,
            searchProv: [],
            searchCity: [],
            users: [],
            selectTimeType: 1

        }
    },
    authorize: {
        deleteRecord: 'delete'
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);

        //  客户经理
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
            console.log(res, 'res')
            this.users = res.data.data;
        })
        this.getData()
    },
    computed: {
        userPermissions() {
            return this.$store.state.account.user.authorityList;
        }
    },
    methods: {
        checkoutTypeChange(){
            this.queryData.beginCreateTime = ''
            this.queryData.beginVisitTime = ''
            this.queryData.endCreateTime = ''
            this.queryData.endVisitTime = ''
            this.dateVisiValue = null
            this.dateValue = null
        },
        dateVisiChange(date, dateString) {
            this.queryData.beginVisitTime = dateString[0]
            this.queryData.endVisitTime = dateString[1]
            this.dateVisiValue = date
        },
        provChange(id) {
            this.city = []
            this.queryData.cityList = []
            if (!id) {
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
                if (res.data.code == 200) {
                    this.city = res.data.data;
                    this.city.forEach(item => {
                        this.queryData.cityList.push(item.name)
                    })
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
        cityChange(name) {
            if (name) {
                this.queryData.cityList = [name]
            } else {
                this.queryData.cityList = []
                this.city.forEach(item => {
                    this.queryData.cityList.push(item.name)
                })
            }
        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10
            }
            this.dateValue = ''
            this.dateVisiValue = ''
            this.getData()
        },
        // 选择日期
        dateChange(date, dateString) {
            this.queryData.beginCreateTime = dateString[0]
            this.queryData.endCreateTime = dateString[1]
            this.dateValue = date
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/countCustomerList', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    if(this.selectTimeType == 1 ) {
                        this.dataSource.forEach(item=>{
                            item.offlineVisits = '-'
                            item.onlineCommunication = '-'
                        })
                    } 
                    if(this.selectTimeType == 2) {
                        this.dataSource.forEach(item=>{
                            item.customerNumber = '-'
                            item.dealNumber = '-'
                        })
                    }
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },

        deleteRecord(key) {
            this.dataSource = this.dataSource.filter(item => item.key !== key)
            this.selectedRows = this.selectedRows.filter(item => item.key !== key)
        },
        toggleAdvanced() {
            this.advanced = !this.advanced
        },

    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}

::v-deep .ant-input-group  {
    display: flex !important;
}
</style>